import React from "react"
import { Helmet } from "react-helmet"
import "../styles/style.css"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Isologo from "../../static/images/icons/qualoom/icon_qualoom_blue.png"
import LogoH from "../../static/images/icons/qualoom/logo_qualoom_horizontal.png"
import LogoV from "../../static/images/icons/qualoom/logo_qualoom_vertical.png"
import Aniversario from "../../static/images/firma_aniversario_qualoom.png"
import { Link } from "gatsby"

const Legal = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Aviso Legal | Qualoom</title>
        <meta name="title" content="Aviso Legal | Qualoom"/>
        <meta name="description" content="Consulta nuestro Aviso legal, Política de Privacidad y Política de Cookies. Las hemos preparado para que sean sencillas, claras y concisas."/>
        <html lang='es'/>        <link rel="canonical" href=""/>
        <link rel="canonical" href="https://www.qualoom.es/aviso-legal/"/>
        <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-legal">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Por encima de todo, claridad y honestidad</h1>
              <p>Consulta nuestro Aviso legal, Política de Privacidad y Política de Cookies. Las hemos preparado para que sean sencillas, claras y concisas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="content">
        <h2>Aviso Legal</h2>
        <hr></hr>
        <p>Qualoom Expertise Technology, S.L. (en adelante, el Titular) con NIF B85437531, y domicilio en Móstoles, Plaza de Valencia, 6 - Bajo Local 28937 Móstoles (Madrid) es titular del Sitio Web accesible a través de la URL: <Link to="/">www.qualoom.es</Link> (en adelante, el Sitio Web). La sociedad se encuentra registrada en el Registro Mercantil de Madrid Tomo: 25605, Folio: 40, Sección: 8, Hoja registral: 461399 e Inscripción: 1.</p>
        <p>Para cualquier consulta, duda o sugerencia respecto al Sitio Web, puedes ponerte en contacto con nosotros a través de la dirección de correo electrónico <a href="mailto:contacto@qualoom.es">contacto@qualoom.es</a>.</p>

        <h3>1. Condiciones de uso</h3>
        <p>Las presentes condiciones regulan el uso del sitio web donde se facilita al público información relativa a los servicios de Qualoom Expertise Technology. Le rogamos lea detenidamente la información que le facilitamos. El hecho de acceder a este sitio web y utilizar los materiales contenidos en ella implica que usted ha leído y acepta, sin reserva alguna, estas condiciones.</p>
        <p>Qualoom Expertise Technology se reserva el derecho de denegar, suspender, interrumpir o cancelar el acceso o la utilización, total o parcialmente, de este sitio web a aquellos usuarios o visitantes del mismo que incumplan cualquiera de las condiciones previstas en este Aviso Legal.</p>
        <p>Este sitio web contiene materiales, como artículos, estudios, descripción de proyectos, preparados por Qualoom Expertise Technology con fines únicamente informativos. Qualoom Expertise Technology le informa que estos materiales puedes ser modificados, desarrollados o actualizados en cualquier momento sin notificación previa.</p>

        <h3>2. Derechos de Propiedad Intelectual e Industrial</h3>
        <p>© Qualoom Expertise Technology, S.L. Todos los derechos reservados.</p>
        <p>La totalidad del contenido de este sitio web, entendiendo por contenido a titulo meramente enunciativo, textos, imágenes, ficheros, fotografías, logotipos, gráficos, marcas, iconos, combinaciones de colores, o cualquier otro elemento, su estructura y diseño, la selección y forma de presentación de los materiales incluidos en la misma, y el software, links y demás contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos fuentes necesarios para su funcionamiento, acceso y utilización, están protegidos por derechos de propiedad industrial e intelectual, titularidad de Qualoom Expertise Technology o de terceros, sin que puedan entenderse cedidos los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso del sitio Web.</p>
        <p>En particular, quedan prohibidas la reproducción, la transformación, distribución, comunicación pública, puesta a disposición del público y en general cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de este sitio web, así como de su diseño y la selección y forma de presentación de los materiales incluidos en la misma.</p>
        <p>Estos actos de explotación sólo podrán ser realizados si media la autorización expresa de Qualoom Expertise Technology y siempre que se haga referencia a la titularidad de Qualoom Expertise Technology de los indicados derechos de propiedad intelectual e industrial.</p>
        <p>Queda asimismo prohibido decompilar, desensamblar, realizar ingeniería inversa, sublicenciar o transmitir de cualquier modo, traducir o realizar obras derivadas de los programas de ordenador necesarios para el funcionamiento, acceso y utilización de este sitio web y de los servicios en él contenidos, así como realizar, respecto a todo o parte de tales programas, cualesquiera de los actos de explotación descritos en el párrafo anterior.</p>
        <p>El usuario del sitio web deberá abstenerse en todo caso de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistemas de seguridad que puedan estar instalados en el mismo.</p>
        <p>Las marcas, nombres comerciales o signos distintivos son titularidad de Qualoom Expertise Technology o de terceros, sin que pueda entenderse que el acceso al sitio web atribuya ningún derecho sobre las citadas marcas, nombres comerciales y/o signos distintivos.</p>

        <h3>3. Hiperenlaces</h3>
        <p>Los hiperenlaces o links que contiene este sitio web pueden conducir al usuario a otros sitios y páginas web gestionados por terceros, sobre las que Qualoom Expertise Technology no ejerce ningún tipo de control. Qualoom Expertise Technology no responde ni de los contenidos ni del estado de dichos sitios y páginas web, y el acceso a las mismas a través de este sitio web tampoco implica que Qualoom Expertise Technology recomiende o apruebe sus contenidos.</p>

        <h3>4. Modificaciones</h3>
        <p>Con el fin de mejorar el sitio web, Qualoom Expertise Technology se reserva el derecho, en cualquier momento y sin previa notificación, a modificar, ampliar o suspender temporalmente la presentación, configuración, especificaciones técnicas y servicios del sitio web, de forma unilateral.</p>
        <p>Asimismo se reserva el derecho de modificar en cualquier momento las presentes condiciones de uso así como cualesquiera otras condiciones particulares.</p>

        <h3>5. Exclusión de responsabilidad</h3>
        <p>Quien utiliza este sitio web, lo hace por su propia cuenta y riesgo. Qualoom Expertise Technology, sus socios, colaboradores, empleados y representantes no se responsabilizan de los errores u omisiones de los que pudieran adolecer los contenidos este sitio web u otros contenidos a los que se pueda acceder a través de la misma.</p>
        <p>Qualoom Expertise Technology, sus socios, colaboradores, empleados y representantes tampoco podrán ser considerados responsables por cualesquiera daños derivados de la utilización de este sitio web, ni por cualquier actuación realizada sobre la base de la información que en ella se facilita.</p>
        <p>La información de este sitio web se proporciona sin garantía de ninguna clase, ni explícita ni implícita, y podrá cambiarse o actualizarse sin previo aviso.</p>
        <p>Qualoom Expertise Technology no garantiza la ausencia de virus, gusanos u otros elementos informáticos lesivos que pudieran causar daños o alteraciones en el sistema informático, en los documentos electrónicos o en los ficheros del usuario de este sitio web.</p>
        <p>En consecuencia, Qualoom Expertise Technology no responde por los daños y perjuicios que tales elementos pudieran ocasionar al usuario o a terceros. Así mismo no se responsabiliza ni garantiza la disponibilidad y continuidad en el acceso a este sitio web o que el mismo esté libre de errores, correspondiendo al usuario del sitio Web, en cualquier caso, la obligación de disponer herramientas adecuadas para la detección y desinfección de programas informáticos dañinos o lesivos.</p>
        <p>El usuario responderá de los daños y perjuicios de toda naturaleza que Qualoom Expertise Technology pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido por las presentes condiciones. El usuario es consciente y acepta voluntariamente que el uso de cualesquiera contenidos de este sitio web tiene lugar, en todo caso, bajo su única y exclusiva responsabilidad.</p>

        <h3>6. Protección de datos y cookies</h3>
        <p>Los usuarios que quieran conocer qué tratamientos de datos se realizan en la página web pueden consultar nuestra Política de Privacidad. Asimismo, los Usuarios que quieran obtener información sobre las cookies utilizadas en la página web, pueden consultar nuestra <Link to="/politica-de-cookies/">Política de Cookies</Link>.</p>

        <h3>7. Atención al cliente</h3>
        <p>Si tiene cualquier duda, sugerencia, reclamación o quiere realizar alguna consulta sobre nuestra página web, contacte con nosotros.</p>

        <h3>8. Legislación</h3>
        <p>Las presentes condiciones de uso se rigen en todos y cada uno de sus extremos por la legislación española.</p>
      </div>
      <div className="hide icons_qualoom">
        <img src={Isologo} alt="Isologo Qualoom"></img>
        <img src={LogoV} alt="Logo Qualoom"></img>
        <img src={LogoH} alt="Logo Qualoom"></img>
        <img src={Aniversario} alt="Logo Aniversario"></img>
      </div>
      <Footer />
    </div>
  )
}

export default Legal
